import Canvas from './background/canvas.js'
import Navbar from './components/Navbar.js'
import About from './pages/About.js'
import Resume from './pages/Resume.js'
import Portfolio from './pages/Portfolio.js'
import './App.css';
import { Route, Switch, HashRouter } from "react-router-dom";
import {Box, Grid} from "@mui/material";


import SocialIcon from "./components/SocialIcon.js"

function App() {

  let Component
  switch (window.location.pathname){
    case "/":
      Component = About
      break
    case "/resume":
      Component = Resume
      break
    case "/portfolio":
      Component = Portfolio
      break
  }


  return (
    <div>
      <script src="https://kit.fontawesome.com/5f44bbffb2.js" crossOrigin="anonymous"></script>
      <Canvas id="gol" />
      <Navbar />
      <Component />
      <Grid item>
         <Box component={'footer'} display={'flex'} flexDirection={'column'} alignItems={'center'}
              py={'1.5rem'} sx={{opacity: 0.7}} width={'100%'}>
            <p style={{'color':'white'}}>Tal Isaac Zilkha 2022</p>
         </Box>
      </Grid>    
    </div>
  );
}


export default App;

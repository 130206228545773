import "./Navbar.css"

export default function Navbar() {
	return (
	<nav className="nav">
    <div className="container">
        <ul>
          <li><a href="/">About Me</a></li>
          <li><a href="/portfolio">Portfolio</a></li>
          <li><a href="/resume">Resume</a></li>
        </ul>
    </div>
	</nav>
	);
}
export let colors = ["rgb(0,255,164)", "rgb(166,104,255)"];

export const info = {
	socials: [
        {
            link: "https://github.com/tzilkha",
            icon: "github",
            label: 'github'
        },
        {
            link: "https://www.linkedin.com/in/tal-isaac-zilkha-293023158/",
            icon: "linkedin",
            label: 'linkedin'
        },
        {
            link: "https://t.me/kingdomtiz",
            icon: "telegram",
            label: 'telegram'
        },
        {
            link: "https://discord.com/users/TIZ#7345",
            icon: "discord",
            label: 'discord'
        },
        {
            link: "https://twitter.com/kingdomtiz",
            icon: "twitter",
            label: 'twitter'
        },
        {
            link: "mailto:tzilkha@gmail.com",
            icon: "google",
            label: 'gmail'
        }
    ],
    miniBio: [
        {
            emoji: '👨🏻‍💻',
            text: 'Self Learner'
        },
        {
            emoji: '🌎',
            text: 'International mindset'
        },
        {
            emoji: "🚀",
            text: "Passion for emerging tech theoretics"
        },
        {
            emoji: "🎹",
            text: "Hobby musician"
        }
    ],
    experience: {
        backend: [
            {
                skill:'Python',
                level:'Experienced'
            },
            {
                skill:'BASH',
                level:'Experienced'
            },
            {
                skill:'AWS',
                level:'Intermediate'
            },
            {
                skill:'C',
                level:'Intermediate'
            },
            {
                skill:'C++',
                level:'Intermediate'
            },
            {
                skill:'Java',
                level:'Intermediate'
            },
            {
                skill:'JavaScript',
                level:'Intermediate'
            },
        	{
        		skill:'SQL',
        		level:'Intermediate'
        	},
            {
                skill:'SQLAlchemy',
                level:'Beginner'
            },
            {
                skill:'FastAPI',
                level:'Beginner'
            }
        ],
        data: [
            {
                skill:'NumPy',
                level:'Experienced'
            },
            {
                skill:'Pandas',
                level:'Experienced'
            },
            {
                skill:'SciKit',
                level:'Experienced'
            },
            {
                skill:'R',
                level:'Intermediate'
            },
            {
                skill:'TensorFlow',
                level:'Intermediate'
            },
            {
                skill:'PyTorch',
                level:'Intermediate'
            },
            {
                skill:'SAS',
                level:'Beginner'
            }
        ],
        other: [
            {
                skill:'Rust',
                level:'Intermediate'
            },
            {
                skill:'Circomlib',
                level:'Intermediate'
            },
            {
                skill:'NEAR CLI',
                level:'Intermediate'
            },
            {
                skill:'HTML',
                level:'Intermediate'
            },
            {
                skill:'React',
                level:'Intermediate'
            },
            {
                skill:'Git',
                level:'Intermediate'
            },
            {
                skill:'npm',
                level:'Intermediate'
            }
        ],
    },
    resume: [
            {
                text: 'Co-Founder @ Nela Network',
                date: 'Jan 2021 - Jun 2022',
                description: 'Co-founded a blockchain project with the aim of solving IP protection and privacy of digital assets across Web3. I was in charge of fundraising, creating the tokenomics structure and product strategy. We raised over one million USD, had over 30 developers contributing, and created a working private test-net. Due to market fit and bear market conditions, we decided to freeze the project.',
                category: {
                    tag: 'education'
                }
            },
            {
                text: 'MS in Computer Science @ Columbia University',
                date: 'Sep 2020 - Dec 2021',
                description: 'Completed an MS degree in Computer Science at the Fu Foundation School of Engineering at Columbia University. I completed the Machine Learning track and enrolled in specialized courses such as Unsupervised Learning, Bayesian Statistics, Survival Analysis, Computer Vision, and Big Data.',
                category: {
                    tag: 'education'
                }
            },
            {
                text: 'Summer ML Engineer @ Cheetah',
                date: 'Jul 2019 - Aug 2019',
                description: 'I was in charge of improving the company\'s churn prediction model. In the process, I compared and tested different model metrics such as F1, balanced accuracy and log loss to determine which is most relevant to the problem. I deployed different models such as random forest models and gradient boosting models, performed feature engineering and constructed new cross validation splits to further align with the time series nature of the data.',
                category: {
                    tag: 'work'
                }
            },
            {
                text: 'Teacher Assistant @ Columbia University',
                date: 'May 2019 - May 2020',
                description: 'During my time at Columbia, I worked as a teacher\'s assitant for Calculus I and Computer Science Theory courses where I worked closely with students to help them understand the concepts taught in the class and help the professor grade homeworks and exams.',
                category: {
                    tag: 'work'
                }
            },
            {
                text: 'Summer Data Analyst @ Pagaya',
                date: 'Jun 2018 - Aug 2018',
                description: 'In my second year at Columbia I worked at Pagaya, a fintech company who recently went public at a valuation of $8B. I was tasked with creating a statistical model for their credit investments in the peer to peer lending markets. The project was aimed at providing insights as to whether two identities on different peer-to-peer lending platforms were of the same person. Since lending happens anonymously, a likelihood of two anonymous identities being the same must be calculated based on financial and demographic information. Using a recently published paper from the Financial Management Association, I created a scheme to detect these identities. The statistical model was documented, used to provide insights to management, and introduced to the day-to-day operations of the company.',
                category: {
                    tag: 'work'
                }
            },
            {
                text: 'BA in Computer Science/Statistics @ Columbia University',
                date: 'Sep 2016 - May 2020',
                description: 'I completed my undergraduate degree in Computer Science with a concentration (minor) in Statistics at Columbia University. During my time there, I completed the AI track, taking classes such as Natural Language Processing, Machine Learning, and Deep Learning. I graduated with a 4.06 GPA and was awarded the Jonathan L. Gross Award for being top of my class, inducted into Columbia University\'s Honor Society, and Phi Beta Kappa',
                category: {
                    tag: 'education'
                }
            },
            {
                text: 'Non-Comissioned Intelligence Officer @ IDF Intelligence',
                date: 'Oct 2012 - Dec 2015',
                description: 'Right after high-school, I spent three years in a classified intelligence unit at the Israeli Defence Force. My job involved aggregating and analyzing intelligence in preparation and planning of classified operations. I worked closely with other bodies in the intelligence community, advised high-ranking generals, community-wide decisions and constructed protocols for the day-to-day and emergency situations which are implemented to this day.',
                category: {
                    tag: 'work'
                }
            }
    ],
    projects: [
        {
            title: "VOAN",
            link: "",
            description: "Anonymous voting framework for NEAR. Submission for MetaBUILD III hackathon.",
            tags: ["circom", "rust", "blockchain", "js"]
        },
        {
            title: "MimcSponge RS",
            link: "https://github.com/tzilkha/mimc-sponge-rs",
            description: "A Rust implementation of the MiMCSponge hash function. Compatible with iden3's circom and optimized for NEAR.",
            tags: ["circom", "rust"]
        },
        {
            title: "Tazmania",
            link: "https://github.com/tzilkha/Tazmania",
            description: "A mixer protocol using Zk-Snarks built on the NEAR blockchain. Inspired by TornadoCash.",
            tags: ["blockchain", "zk", "circom", "rust", "js"]
        },
        {
            title: "BTC Wallet Tracker",
            link: "https://github.com/tzilkha/WalletTracker",
            description: "API for BTC account tracking for multiple users. Backend quering of external APIs to aggregate in local SQL database.",
            tags: ["fastapi", "sqlalchemy", "python"]
        },
        {
            title: "Stock Sentiment Scraper",
            link: "https://github.com/tim-kao/CloudComputingProject",
            description: "New metrics for stocks based on emotional sentiment of posts on popular social media. Inspired by GameStop short-squeeze and WallStreetBets. Done using an AWS based architecture.",
            tags: ["aws", "scraping", "nlp", "python"]
        },
        {
            title: "AlphaNP",
            link: "https://github.com/tzilkha/AlphaNP",
            description: "Deep Learning class project. Experimental implementation of AlphaZero to learn through LSTMs tested on NP problems. Attempting to create a more aggresize winning strategy.",
            tags: ["alphazero", "lstm", "numpy", "tensorflow", "python"]
        }
    ],
    firstName: "Tal",
    lastName: "Zilkha",
    initials: "TIZ", 
    position: "Machine Learning, Data Science, & Blockchain",
    gradient: `-webkit-linear-gradient(135deg, ${colors})`, // don't change this either
    baseColor: colors[0],
}